import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IsocialIcon } from '@modules/home/interfaces';

@Component({
  selector: 'app-social-networks',
  templateUrl: './social-networks.component.html',
  styleUrls: ['./social-networks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialNetworksComponent {
  // Todo - mover configuracion de iconos sociales a modulo de data
  socialNetworks: IsocialIcon[] = [
    // {
    //   name: 'twitter',
    //   image: 'https://storage.googleapis.com/tribuu-bucket/Assets/icons/tw.png',
    //   link: 'https://twitter.com/TribuuMx',
    // },
    {
      name: 'facebook',
      image: 'https://storage.googleapis.com/tribuu-bucket/Assets/icons/fb.png',
      link: 'https://www.facebook.com/profile.php?id=100087121921742',
    },
    {
      name: 'tiktok',
      image: 'https://storage.googleapis.com/tribuu-bucket/Assets/icons/tt.png',
      link: 'https://www.tiktok.com/@tribuucomunidadtalento',
    },
    {
      name: 'linkedin',
      image: 'https://storage.googleapis.com/tribuu-bucket/Assets/icons/in.png',
      link: 'https://www.linkedin.com/company/tribuu-comunidad-laboral/',
    },
    {
      name: 'instagram',
      image: 'https://storage.googleapis.com/tribuu-bucket/Assets/icons/ig.png',
      link: 'https://www.instagram.com/tribuu_comunidadtalento/',
    },
    {
      name: 'youtube',
      image: 'https://storage.googleapis.com/tribuu-bucket/Assets/icons/yt.png',
      link: 'https://www.youtube.com/channel/UCvcQb7nDQgC97Xgr93Hv-TQ',
    },
  ];
}
