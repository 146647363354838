import { inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AuthApi } from '@tribuu-api';
import { firstValueFrom } from 'rxjs';

export const LinkedinAuthHandlerGuard = async (route: ActivatedRouteSnapshot) => {
  const afAuth = inject(AngularFireAuth);
  const authApi = inject(AuthApi);
  const code = route.queryParams['code'];

  try {
    const authUser = await firstValueFrom(authApi.loginWithLinkedinCode({ code }));
    await afAuth.signInWithCustomToken(authUser.token);
  } catch {
    afAuth.signOut();
  }

  window.close();
};
