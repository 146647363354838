import { Injectable, inject } from '@angular/core';
import { MessagesApi, ReservationsApi } from '@tribuu-api';
import { Action, Actions, Selector, State, StateContext, ofActionSuccessful } from '@ngxs/store';
import { TuiAlertService, TuiDialogService } from '@taiga-ui/core';
import { tap } from 'rxjs';
import { AuthActions } from '../auth/auth.actions';
import { ConversationsActions } from './conversations.actions';
import { ReservationConversation } from './conversations.models';

export interface ConversationsStateModel {
  conversations: ReservationConversation[];
}

@State<ConversationsStateModel>({
  name: 'conversations',
  defaults: {
    conversations: [],
  },
})
@Injectable()
export class ConversationsState {
  private readonly reservationsApi = inject(ReservationsApi);
  private readonly messagesApi = inject(MessagesApi);
  private readonly alerts = inject(TuiAlertService);
  private readonly actions = inject(Actions);
  private dialogs = inject(TuiDialogService);

  logout$ = this.actions.pipe(ofActionSuccessful(AuthActions.Logout));

  // Hooks
  // ngxsOnInit(ctx: StateContext<ConversationsStateModel>): void {}

  @Selector() static Conversations(state: ConversationsStateModel) {
    if (!state.conversations) {
      return [];
    }
    return state.conversations.filter((conversation) => !conversation.isRemoved);
  }

  @Action(ConversationsActions.FetchAll)
  fetchAll(
    ctx: StateContext<ConversationsStateModel>,
    { conversations }: ConversationsActions.FetchAll
  ) {
    const conversationsOnStore = ctx.getState().conversations;
    conversations.forEach((conversation) => {
      const { uid } = conversation;
      const found = this.searchConverstion(conversationsOnStore, uid);
      if (!found) {
        // conversationsOnStore.push(conversation);
        ctx.patchState({ conversations: [...conversationsOnStore, conversation] });
      }
    });

    return ctx.patchState({ conversations: conversationsOnStore });
  }

  @Action(ConversationsActions.OpenConversation)
  openConversation(
    ctx: StateContext<ConversationsStateModel>,
    { reservationId, fromUser }: ConversationsActions.OpenConversation
  ) {
    const { conversations } = ctx.getState();
    const found = this.searchConverstion(conversations, reservationId);
    if (!found) return;

    if (fromUser) {
      found.fromUser = fromUser;
    }

    found.isRemoved = false;
    found.isOpened = true;
    found.loadingMessages = true;
    ctx.patchState({ conversations });

    return this.messagesApi.getMessagesFromReservation({ uid: reservationId }).pipe(
      tap((messages) => {
        found.messages = messages;
        found.loadingMessages = false;
        ctx.patchState({ conversations });
      })
    );
  }

  @Action(ConversationsActions.CloseConversation)
  closeConversation(
    ctx: StateContext<ConversationsStateModel>,
    { reservationId }: ConversationsActions.CloseConversation
  ) {
    const { conversations } = ctx.getState();
    const found = this.searchConverstion(conversations, reservationId);

    if (!found) return;

    found.isRemoved = true;
    return ctx.patchState({ conversations });
  }

  @Action(ConversationsActions.ToogleConversation)
  toogleConversation(
    ctx: StateContext<ConversationsStateModel>,
    { reservationId }: ConversationsActions.ToogleConversation
  ) {
    const { conversations } = ctx.getState();
    const found = this.searchConverstion(conversations, reservationId);
    if (!found) {
      return;
    }
    found.isOpened = !found.isOpened;
    ctx.patchState({ conversations });
  }

  @Action(ConversationsActions.SendMessage)
  sendMessage(
    ctx: StateContext<ConversationsStateModel>,
    { payload }: ConversationsActions.SendMessage
  ) {
    const conversations = ctx.getState().conversations;
    const found = this.searchConverstion(conversations, payload.reservationId);

    if (!found) return; // not found

    found.isSending = true;
    ctx.patchState({ conversations });
    return this.messagesApi
      .sendMessage({
        createMessageDto: {
          content: payload.content,
          file: payload.file,
          reservation: { connect: { uid: payload.reservationId } },
        },
      })
      .pipe(
        tap((msg) => {
          found.isSending = false;
          ctx.patchState({ conversations });
          ctx.dispatch(new ConversationsActions.AddMessage(msg));
        })
      );
  }

  @Action(ConversationsActions.AddMessage)
  addMessage(
    ctx: StateContext<ConversationsStateModel>,
    { message }: ConversationsActions.AddMessage
  ) {
    const { conversations } = ctx.getState();
    const found = this.searchConverstion(conversations, message.reservationId);

    if (!found) return;

    found.messages = found.messages ? [message, ...found.messages] : [message];
    return ctx.patchState({ conversations });
  }

  @Action(ConversationsActions.FetchMessages)
  fetchMessages(
    ctx: StateContext<ConversationsStateModel>,
    { reservationId }: ConversationsActions.FetchMessages
  ) {
    const { conversations } = ctx.getState();
    const found = this.searchConverstion(conversations, reservationId);
    if (!found) return;

    return this.messagesApi.getMessagesFromReservation({ uid: reservationId }).pipe(
      tap((messages) => {
        found.messages = messages;
        ctx.patchState({ conversations });
      })
    );
  }

  searchConverstion(conversations: ReservationConversation[], reservationId: string) {
    return conversations.find((conversation) => conversation.uid === reservationId) || null;
  }
}
