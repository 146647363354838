import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WizardService } from '@core/services/internals/wizard.service';
import { INTERNAL_ROUTES } from '@data/constants/routes';
import { tuiPure } from '@taiga-ui/cdk';
import { SharedModule } from '../../../../shared/shared.module';
import { WizardOnboardingComponent } from '../../wizard-onboarding/wizard-onboarding.component';

@Component({
  selector: 'app-wizard-hire-talent',
  standalone: true,
  imports: [WizardOnboardingComponent, SharedModule],
  templateUrl: './wizard-hire-talent.component.html',
})
export class WizardHireTalentComponent {
  private readonly wizardService = inject(WizardService);
  private readonly router = inject(Router);
  private readonly routerActive = inject(ActivatedRoute);

  readonly talentPublishUrl = `/${INTERNAL_ROUTES.APP_WIZARD_TALENT_TYPE}`;
  readonly wizardUrl = `/${INTERNAL_ROUTES.APP_WIZARD_WELCOME}`;

  @tuiPure
  get hasReturnButton() {
    return !this.routerActive.snapshot.queryParams?.hasReturnButton
      ? true
      : JSON.parse(this.routerActive.snapshot.queryParams.hasReturnButton);
  }

  searchClick(): void {
    this.wizardService.setOmit();
    this.router.navigateByUrl(INTERNAL_ROUTES.APP_PUBLICATION_SEARCH);
  }
}
