<app-wizard-onboarding>
  <!-- ng content -->
  <p class="text-center text-3xl font-bold">¡Bienvenido a la Tribuu!</p>

  <img src="assets/images/home/trazo.svg" alt="trazo" class="mx-auto mt-7 h-[35px]" />

  <p class="mt-7 text-center leading-tight">Selecciona la opción de tu interés:</p>

  <div class="mt-7 flex w-full justify-center gap-5">
    <figure
      class="border-primary w-1/3 cursor-pointer rounded-xl border-2 p-5 max-md:px-2 max-sm:w-5/12"
      [routerLink]="hireTalentURL">
      <div class="w-full">
        <img src="assets/images/home/wizard-one-opt1.png" alt="opt one" class="w-full object-contain" />
      </div>
      <figcaption class="text-primary mt-5 w-full text-center text-lg font-bold leading-[18px]">
        Contratar
        <br />
        talento
      </figcaption>
    </figure>

    <figure
      class="border-primary w-1/3 cursor-pointer rounded-xl border-2 p-5 max-md:px-2 max-sm:w-5/12"
      [routerLink]="talentPublishURL">
      <div class="w-full">
        <img
          src="assets/images/home/wizard-one-opt2.png"
          alt="opt one"
          class="mx-auto w-3/4 object-contain" />
      </div>
      <figcaption class="text-primary mt-5 w-full text-center text-lg font-bold leading-[18px]">
        Publicar
        <br />
        mi talento
      </figcaption>
    </figure>
  </div>
</app-wizard-onboarding>
