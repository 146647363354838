import { NgModule, Optional, SkipSelf } from '@angular/core';
import { WizardService } from './services/internals/wizard.service';

@NgModule({
  declarations: [],
  imports: [],
})
export class CoreModule {
  constructor(
    private wizardService: WizardService,
    @Optional() @SkipSelf() parentModule?: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only.');
    }
  }
}
