<ng-container *ngIf="userInfo() as info; else empty">
  <div
    class="group flex cursor-pointer items-center justify-center gap-1.5 overflow-hidden rounded pr-2"
    tuiDropdownHover
    #dropdown="tuiDropdown"
    [tuiDropdownShowDelay]="0"
    [tuiDropdownHideDelay]="200"
    [tuiDropdownOffset]="4"
    [tuiDropdown]="dropdownMenuContent">
    <img
      [src]="info.img"
      referrerpolicy="no-referrer"
      class="h-12 w-12 rounded-xl object-cover"
      alt="avatar image"
      title="mi foto de perfil"
      lazyLoad
      appImageFallback />
    <span class="w-24 truncate text-center text-sm">{{ info.name | titlecase }}</span>
    <svg-icon
      class="h-5 w-5 transition-transform duration-150 group-hover:rotate-180"
      src="assets/images/icons/menu/chevron-down.svg"></svg-icon>
    <ng-template #dropdownMenuContent>
      <div [style.width.px]="dropdown.getClientRect().width">
        <app-menu-user-auth-opts></app-menu-user-auth-opts>
      </div>
    </ng-template>
  </div>
</ng-container>

<ng-template #empty>
  <div class="flex animate-pulse items-center justify-center gap-1.5 overflow-hidden rounded pr-2">
    <div class="h-12 w-12 rounded-xl bg-slate-400"></div>
    <div class="h-2.5 w-24 bg-gray-200"></div>
    <svg-icon
      class="h-5 w-5 transition-transform duration-150 group-hover:rotate-180"
      src="assets/images/icons/menu/chevron-down.svg"></svg-icon>
  </div>
</ng-template>
