import { TuiCountryIsoCode } from '@taiga-ui/i18n/enums';
const TUI_SPANISH_LANGUAGE_ADDON_COMMERCE = {
  cardNumber: ['Número', 'Número de la tarjeta'],
  cardExpiry: ['Expira', 'Fecha de expiración']
};
const TUI_SPANISH_LANGUAGE_ADDON_EDITOR = {
  colorSelectorModeNames: ['Color sólido', 'Gradiente'],
  toolbarTools: {
    undo: 'Deshacer',
    redo: 'Rehacer',
    font: 'Fuente',
    fontStyle: 'Estilo de la fuente',
    fontSize: 'Tamaño de la fuente',
    bold: 'Negrita',
    italic: 'Cursiva',
    underline: 'Subrayado',
    strikeThrough: 'Tachado',
    justify: 'Justificar',
    justifyLeft: 'Alinear a izquierda',
    justifyCenter: 'Centrar',
    justifyRight: 'Alinear a derecha',
    justifyFull: 'Justificar',
    list: 'Listar',
    indent: 'Sangría',
    outdent: 'Sin sangría',
    unorderedList: 'Lista desordenada',
    orderedList: 'Lista ordenada',
    quote: 'Cita',
    foreColor: 'Color',
    backColor: 'Color de fondo',
    hiliteColor: 'Color de resaltado',
    clear: 'Limpiar',
    link: 'Enlace',
    attach: 'Adjuntar archivo',
    tex: 'Insertar texto',
    code: 'Código',
    image: 'Insertar imagen',
    insertHorizontalRule: 'Insertar regla horizontal',
    superscript: 'Superíndice',
    subscript: 'Subíndice',
    insertTable: 'Insert table',
    hiliteGroup: 'Hilite group',
    insertGroup: 'Insert group',
    removeGroup: 'Remove group',
    insertAnchor: 'Insert anchor',
    mergeCells: 'Merge cells',
    splitCells: 'Split cells',
    rowsColumnsManaging: 'Managing rows and columns',
    cellColor: 'Cell color',
    setDetails: 'Details',
    removeDetails: 'Remove details'
  },
  editorEditLink: {
    urlExample: 'example.com',
    anchorExample: 'anchor'
  },
  editorTableCommands: [['Insert column before', 'Insert column after'], ['Insert row before', 'Insert row after'], ['Delete column', 'Delete row']],
  editorCodeOptions: ['Code in the text', 'Code in block'],
  editorFontOptions: {
    small: 'Small',
    normal: 'Normal',
    large: 'Large',
    title: 'Title',
    subtitle: 'Subtitle'
  }
};
const TUI_SPANISH_LANGUAGE_PREVIEW = {
  previewTexts: {
    rotate: 'Rotate'
  },
  zoomTexts: {
    zoomOut: 'Zoom out',
    zoomIn: 'Zoom in',
    reset: 'Reset'
  }
};
const TUI_SPANISH_LANGUAGE_ADDON_TABLE = {
  showHideText: 'Mostrar/Ocultar',
  paginationTexts: {
    pages: 'Páginas',
    linesPerPage: 'Lineas por página',
    of: 'de'
  }
};
const TUI_SPANISH_LANGUAGE_COUNTRIES = {
  [TuiCountryIsoCode.AD]: 'Andorra',
  [TuiCountryIsoCode.AE]: 'Emiratos Árabes Unidos',
  [TuiCountryIsoCode.AF]: 'Afganistán',
  [TuiCountryIsoCode.AG]: 'Antigua y Barbuda',
  [TuiCountryIsoCode.AI]: 'Anguila',
  [TuiCountryIsoCode.AL]: 'Albania',
  [TuiCountryIsoCode.AM]: 'Armenia',
  [TuiCountryIsoCode.AO]: 'Angola',
  [TuiCountryIsoCode.AR]: 'Argentina',
  [TuiCountryIsoCode.AT]: 'Austria',
  [TuiCountryIsoCode.AU]: 'Australia',
  [TuiCountryIsoCode.AW]: 'Aruba',
  [TuiCountryIsoCode.AZ]: 'Azerbaiyán',
  [TuiCountryIsoCode.BA]: 'Bosnia y Herzegovina',
  [TuiCountryIsoCode.BB]: 'Barbados',
  [TuiCountryIsoCode.BD]: 'Bangladesh',
  [TuiCountryIsoCode.BE]: 'Bélgica',
  [TuiCountryIsoCode.BF]: 'Burkina Faso',
  [TuiCountryIsoCode.BG]: 'Bulgaria',
  [TuiCountryIsoCode.BH]: 'Bahréin',
  [TuiCountryIsoCode.BI]: 'Burundi',
  [TuiCountryIsoCode.BJ]: 'Benín',
  [TuiCountryIsoCode.BL]: 'San Bartolomé',
  [TuiCountryIsoCode.BM]: 'Bermudas',
  [TuiCountryIsoCode.BN]: 'Brunéi',
  [TuiCountryIsoCode.BO]: 'Bolivia',
  [TuiCountryIsoCode.BQ]: 'Bonaire, San Eustaquio y Saba',
  [TuiCountryIsoCode.BR]: 'Brasil',
  [TuiCountryIsoCode.BS]: 'Bahamas',
  [TuiCountryIsoCode.BT]: 'Bután',
  [TuiCountryIsoCode.BW]: 'Botsuana',
  [TuiCountryIsoCode.BY]: 'Bielorrusia',
  [TuiCountryIsoCode.BZ]: 'Belice',
  [TuiCountryIsoCode.CA]: 'Canadá',
  [TuiCountryIsoCode.CD]: 'República Democrática del Congo',
  [TuiCountryIsoCode.CF]: 'República Centro-Africana',
  [TuiCountryIsoCode.CG]: 'Congo',
  [TuiCountryIsoCode.CH]: 'Suiza',
  [TuiCountryIsoCode.CI]: 'Costa de Marfil',
  [TuiCountryIsoCode.CL]: 'Chile',
  [TuiCountryIsoCode.CM]: 'Camerún',
  [TuiCountryIsoCode.CN]: 'China',
  [TuiCountryIsoCode.CO]: 'Colombia',
  [TuiCountryIsoCode.CR]: 'Costa Rica',
  [TuiCountryIsoCode.CU]: 'Cuba',
  [TuiCountryIsoCode.CV]: 'Cabo Verde',
  [TuiCountryIsoCode.CW]: 'Curazao',
  [TuiCountryIsoCode.CY]: 'Chipre',
  [TuiCountryIsoCode.CZ]: 'República Checa',
  [TuiCountryIsoCode.DE]: 'Alemania',
  [TuiCountryIsoCode.DJ]: 'Yibuti',
  [TuiCountryIsoCode.DK]: 'Dinamarca',
  [TuiCountryIsoCode.DM]: 'Dominica',
  [TuiCountryIsoCode.DO]: 'República Dominicana',
  [TuiCountryIsoCode.DZ]: 'Algeria',
  [TuiCountryIsoCode.EC]: 'Ecuador',
  [TuiCountryIsoCode.EE]: 'Estonia',
  [TuiCountryIsoCode.EG]: 'Egipto',
  [TuiCountryIsoCode.ER]: 'Eritrea',
  [TuiCountryIsoCode.ES]: 'España',
  [TuiCountryIsoCode.ET]: 'Etiopía',
  [TuiCountryIsoCode.FI]: 'Finlandia',
  [TuiCountryIsoCode.FJ]: 'Fiyi',
  [TuiCountryIsoCode.FK]: 'Islas Malvinas',
  [TuiCountryIsoCode.FM]: 'Micronesia',
  [TuiCountryIsoCode.FR]: 'Francia',
  [TuiCountryIsoCode.GA]: 'Gabón',
  [TuiCountryIsoCode.GB]: 'Reino Unido',
  [TuiCountryIsoCode.GD]: 'Granada',
  [TuiCountryIsoCode.GE]: 'Georgia',
  [TuiCountryIsoCode.GF]: 'Guayana',
  [TuiCountryIsoCode.GH]: 'Ghana',
  [TuiCountryIsoCode.GI]: 'Gibraltar',
  [TuiCountryIsoCode.GL]: 'Groenlandia',
  [TuiCountryIsoCode.GM]: 'Gambia',
  [TuiCountryIsoCode.GN]: 'Guinea',
  [TuiCountryIsoCode.GP]: 'Guadalupe',
  [TuiCountryIsoCode.GQ]: 'Guinea Ecuatorial',
  [TuiCountryIsoCode.GR]: 'Grecia',
  [TuiCountryIsoCode.GT]: 'Guatemala',
  [TuiCountryIsoCode.GW]: 'Guinea-Bisáu',
  [TuiCountryIsoCode.GY]: 'Guyana',
  [TuiCountryIsoCode.HK]: 'Hong Kong',
  [TuiCountryIsoCode.HN]: 'Honduras',
  [TuiCountryIsoCode.HR]: 'Croacia',
  [TuiCountryIsoCode.HT]: 'Haití',
  [TuiCountryIsoCode.HU]: 'Hungría',
  [TuiCountryIsoCode.ID]: 'Indonesia',
  [TuiCountryIsoCode.IE]: 'Irlanda',
  [TuiCountryIsoCode.IL]: 'Israel',
  [TuiCountryIsoCode.IN]: 'India',
  [TuiCountryIsoCode.IQ]: 'Irak',
  [TuiCountryIsoCode.IR]: 'Irán',
  [TuiCountryIsoCode.IS]: 'Islandia',
  [TuiCountryIsoCode.IT]: 'Italia',
  [TuiCountryIsoCode.JM]: 'Jamaica',
  [TuiCountryIsoCode.JO]: 'Jordania',
  [TuiCountryIsoCode.JP]: 'Japón',
  [TuiCountryIsoCode.KE]: 'Kenia',
  [TuiCountryIsoCode.KG]: 'Kirguistán',
  [TuiCountryIsoCode.KH]: 'Camboya',
  [TuiCountryIsoCode.KM]: 'Comoros',
  [TuiCountryIsoCode.KN]: 'San Cristóbal y Nieves',
  [TuiCountryIsoCode.KP]: 'Corea del Norte',
  [TuiCountryIsoCode.KR]: 'Corea del Sur',
  [TuiCountryIsoCode.KW]: 'Kuwait',
  [TuiCountryIsoCode.KY]: 'Islas Caimán',
  [TuiCountryIsoCode.KZ]: 'Kazajstán',
  [TuiCountryIsoCode.LA]: 'Laos',
  [TuiCountryIsoCode.LB]: 'Líbano',
  [TuiCountryIsoCode.LC]: 'Santa Lucía',
  [TuiCountryIsoCode.LI]: 'Liechtenstein',
  [TuiCountryIsoCode.LK]: 'Sri Lanka',
  [TuiCountryIsoCode.LR]: 'Liberia',
  [TuiCountryIsoCode.LS]: 'Lesotho',
  [TuiCountryIsoCode.LT]: 'Lituania',
  [TuiCountryIsoCode.LU]: 'Luxemburgo',
  [TuiCountryIsoCode.LV]: 'Letonia',
  [TuiCountryIsoCode.LY]: 'Libia',
  [TuiCountryIsoCode.MA]: 'Marruecos',
  [TuiCountryIsoCode.MC]: 'M´ónaco',
  [TuiCountryIsoCode.MD]: 'Moldavia',
  [TuiCountryIsoCode.ME]: 'Montenegro',
  [TuiCountryIsoCode.MF]: 'San Martín',
  [TuiCountryIsoCode.MG]: 'Madagascar',
  [TuiCountryIsoCode.MK]: 'Macedonia',
  [TuiCountryIsoCode.ML]: 'Mali',
  [TuiCountryIsoCode.MM]: 'Myanmar',
  [TuiCountryIsoCode.MN]: 'Mongolia',
  [TuiCountryIsoCode.MO]: 'Macao',
  [TuiCountryIsoCode.MQ]: 'Martinica',
  [TuiCountryIsoCode.MR]: 'Mauritania',
  [TuiCountryIsoCode.MS]: 'Montserrat',
  [TuiCountryIsoCode.MT]: 'Malta',
  [TuiCountryIsoCode.MU]: 'Mauricio',
  [TuiCountryIsoCode.MV]: 'Maldives',
  [TuiCountryIsoCode.MW]: 'Malawi',
  [TuiCountryIsoCode.MX]: 'México',
  [TuiCountryIsoCode.MY]: 'Malasia',
  [TuiCountryIsoCode.MZ]: 'Mozambique',
  [TuiCountryIsoCode.NA]: 'Namibia',
  [TuiCountryIsoCode.NC]: 'Nueva Caledonia',
  [TuiCountryIsoCode.NE]: 'Níger',
  [TuiCountryIsoCode.NG]: 'Nigeria',
  [TuiCountryIsoCode.NI]: 'Nicaragua',
  [TuiCountryIsoCode.NL]: 'Países Bajos',
  [TuiCountryIsoCode.NO]: 'Noruega',
  [TuiCountryIsoCode.NP]: 'Nepal',
  [TuiCountryIsoCode.NZ]: 'Nueva Zelanda',
  [TuiCountryIsoCode.OM]: 'Omán',
  [TuiCountryIsoCode.PA]: 'Panamá',
  [TuiCountryIsoCode.PE]: 'Perú',
  [TuiCountryIsoCode.PF]: 'Polinesia Francesa',
  [TuiCountryIsoCode.PG]: 'Papúa Nueva Guínea',
  [TuiCountryIsoCode.PH]: 'Filipinas',
  [TuiCountryIsoCode.PK]: 'Pakistán',
  [TuiCountryIsoCode.PL]: 'Polonia',
  [TuiCountryIsoCode.PT]: 'Portugal',
  [TuiCountryIsoCode.PW]: 'Palaos',
  [TuiCountryIsoCode.PY]: 'Paraguay',
  [TuiCountryIsoCode.QA]: 'Catar',
  [TuiCountryIsoCode.RE]: 'Reunión',
  [TuiCountryIsoCode.RO]: 'Rumania',
  [TuiCountryIsoCode.RS]: 'Serbia',
  [TuiCountryIsoCode.RU]: 'Rusia',
  [TuiCountryIsoCode.RW]: 'Ruanda',
  [TuiCountryIsoCode.SA]: 'Arabia Saudita',
  [TuiCountryIsoCode.SB]: 'Islas Salomón',
  [TuiCountryIsoCode.SC]: 'Seychelles',
  [TuiCountryIsoCode.SD]: 'Sudán',
  [TuiCountryIsoCode.SE]: 'Suecia',
  [TuiCountryIsoCode.SG]: 'Singapur',
  [TuiCountryIsoCode.SH]: 'Santa Elena',
  [TuiCountryIsoCode.SI]: 'Eslovenia',
  [TuiCountryIsoCode.SK]: 'Eslovaquia',
  [TuiCountryIsoCode.SL]: 'Sierra Leona',
  [TuiCountryIsoCode.SM]: 'San Marino',
  [TuiCountryIsoCode.SN]: 'Senegal',
  [TuiCountryIsoCode.SO]: 'Somalia',
  [TuiCountryIsoCode.SR]: 'Surinam',
  [TuiCountryIsoCode.ST]: 'Santo Tomé y Príncipe',
  [TuiCountryIsoCode.SV]: 'El Salvador',
  [TuiCountryIsoCode.SX]: 'San Martín',
  [TuiCountryIsoCode.SY]: 'Siria',
  [TuiCountryIsoCode.SZ]: 'Suazilandia',
  [TuiCountryIsoCode.TC]: 'Islas Turcas y Caicos',
  [TuiCountryIsoCode.TD]: 'Chad',
  [TuiCountryIsoCode.TG]: 'Togo',
  [TuiCountryIsoCode.TH]: 'Tailandia',
  [TuiCountryIsoCode.TJ]: 'Tayikistán',
  [TuiCountryIsoCode.TL]: 'Timor Oriental',
  [TuiCountryIsoCode.TM]: 'Turkmenistán',
  [TuiCountryIsoCode.TN]: 'T´únez',
  [TuiCountryIsoCode.TO]: 'Tonga',
  [TuiCountryIsoCode.TR]: 'Turquía',
  [TuiCountryIsoCode.TT]: 'Trinidad y Tobago',
  [TuiCountryIsoCode.TW]: 'Taiwán',
  [TuiCountryIsoCode.TZ]: 'Tanzania',
  [TuiCountryIsoCode.UA]: 'Ucrania',
  [TuiCountryIsoCode.UG]: 'Uganda',
  [TuiCountryIsoCode.US]: 'Estados Unidos',
  [TuiCountryIsoCode.UY]: 'Uruguay',
  [TuiCountryIsoCode.UZ]: 'Uzbekistán',
  [TuiCountryIsoCode.VC]: 'San Vicente y las Granadinas',
  [TuiCountryIsoCode.VE]: 'Venezuela',
  [TuiCountryIsoCode.VG]: 'Islas Vírgenes Británicas',
  [TuiCountryIsoCode.VN]: 'Vietnam',
  [TuiCountryIsoCode.VU]: 'Vanuatu',
  [TuiCountryIsoCode.WS]: 'Samoa',
  [TuiCountryIsoCode.XK]: 'Kosovo',
  [TuiCountryIsoCode.YE]: 'Yemén',
  [TuiCountryIsoCode.YT]: 'Mayotte',
  [TuiCountryIsoCode.ZA]: 'Sudáfrica',
  [TuiCountryIsoCode.ZM]: 'Zambia',
  [TuiCountryIsoCode.ZW]: 'Zimbabue'
};
const TUI_SPANISH_LANGUAGE_CORE = {
  months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
  close: 'Cerrar',
  nothingFoundMessage: 'Nada encontrado',
  defaultErrorMessage: 'El valor es inválido',
  spinTexts: ['Previo', 'Siguiente'],
  shortWeekDays: ['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom'],
  countries: TUI_SPANISH_LANGUAGE_COUNTRIES
};
const TUI_SPANISH_LANGUAGE_KIT = {
  cancel: 'Cancelar',
  done: 'Hecho',
  more: 'Más',
  otherDate: 'Otra fecha...',
  showAll: 'Show all',
  hide: 'Hide',
  mobileCalendarTexts: ['Seleccione el día', 'Seleccione un rango', 'Elige días'],
  range: ['desde', 'hasta'],
  countTexts: ['Más', 'Menos'],
  time: {
    'HH:MM': 'HH:MM',
    'HH:MM:SS': 'HH:MM:SS',
    'HH:MM:SS.MSS': 'HH:MM:SS.MSS'
  },
  dateTexts: {
    DMY: 'dd.mm.yyyy',
    MDY: 'mm.dd.yyyy',
    YMD: 'yyyy.mm.dd'
  },
  digitalInformationUnits: ['B', 'KiB', 'MiB'],
  passwordTexts: ['Mostrar contraseña', 'Ocultar contraseña'],
  copyTexts: ['Copiar', 'Copiado'],
  shortCalendarMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
  pagination: ['Página anterior', 'Página siguiente'],
  fileTexts: {
    loadingError: 'Error de carga',
    preview: 'Previsualizar',
    remove: 'Remover'
  },
  inputFileTexts: {
    defaultLabelSingle: 'o arrástrelo aquí',
    defaultLabelMultiple: 'o arrástrelos aquí',
    defaultLinkSingle: 'Seleccione un archivo',
    defaultLinkMultiple: 'Seleccione archivos',
    maxSizeRejectionReason: 'Archivo demasiado grande ',
    formatRejectionReason: 'Formato de archivo inválido',
    drop: 'Suelte el archivo aquí',
    dropMultiple: 'Suelte los archivos aquí'
  },
  multiSelectTexts: {
    all: 'Seleccionar todo',
    none: 'Seleccionar ninguno'
  },
  prompt: {
    yes: 'Sí',
    no: 'No'
  }
};
const TUI_SPANISH_LANGUAGE = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({
  name: 'spanish'
}, TUI_SPANISH_LANGUAGE_CORE), TUI_SPANISH_LANGUAGE_KIT), TUI_SPANISH_LANGUAGE_ADDON_TABLE), TUI_SPANISH_LANGUAGE_ADDON_COMMERCE), TUI_SPANISH_LANGUAGE_ADDON_EDITOR), TUI_SPANISH_LANGUAGE_PREVIEW);

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_SPANISH_LANGUAGE, TUI_SPANISH_LANGUAGE_ADDON_COMMERCE, TUI_SPANISH_LANGUAGE_ADDON_EDITOR, TUI_SPANISH_LANGUAGE_ADDON_TABLE, TUI_SPANISH_LANGUAGE_CORE, TUI_SPANISH_LANGUAGE_COUNTRIES, TUI_SPANISH_LANGUAGE_KIT, TUI_SPANISH_LANGUAGE_PREVIEW };
