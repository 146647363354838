<app-wizard-onboarding>
  <p class="px-4 text-center text-3xl font-bold">{{ mainTile }}</p>

  <img src="assets/images/home/trazo.svg" alt="trazo" class="mx-auto mt-7 h-[35px]" />

  <p class="mt-7 text-center leading-tight">Selecciona una opción:</p>

  <div class="mt-7 flex w-full justify-center gap-5">
    <figure
      class="border-primary w-1/3 cursor-pointer rounded-xl border-2 p-5 max-md:px-2 max-sm:w-5/12"
      (click)="onClickBDT()">
      <div class="flex h-[105px] w-full items-center justify-center">
        <img src="assets/images/home/wizard-talent-publish-two.png" alt="opt icon" class="object-contain" />
      </div>
      <figcaption class="text-primary mt-5 w-full text-center text-lg font-bold leading-[18px]">
        {{ bdtText }}
      </figcaption>
    </figure>

    <figure
      class="border-primary w-1/3 cursor-pointer rounded-xl border-2 p-5 max-md:px-2 max-sm:w-5/12"
      (click)="onClickPI()">
      <div class="flex h-[105px] w-full items-center justify-center">
        <img src="assets/images/home/wizard-talent-publish-one.png" alt="opt icon" class="object-contain" />
      </div>
      <figcaption class="text-primary mt-5 w-full text-center text-lg font-bold leading-[18px]">
        {{ piText }}
      </figcaption>
    </figure>
  </div>

  <div class="mt-7 flex w-full justify-center" *ngIf="hasReturnButton">
    <app-button-tribuu
      title="Regresar"
      theme="dark"
      optionalClass="w-full"
      class="w-1/3"
      [routerLink]="returnUrl" />
  </div>
</app-wizard-onboarding>
