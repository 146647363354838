import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IsocialIcon } from '@modules/home/interfaces';

@Component({
  selector: 'app-social-icon[icon]',
  templateUrl: './social-icon.component.html',
  styleUrls: ['./social-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialIconComponent {
  @Input() icon!: IsocialIcon;
}
