import { Component } from '@angular/core';
import { Reservation } from '@tribuu-api';
const { ACCEPTED, PAYMENT_SUCCESS } = Reservation.StatusEnum;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor() {}
}
