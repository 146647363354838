<header class="sticky top-0 z-50 bg-white shadow-sm lg:shadow-xl">
  <div class="mx-auto max-w-screen-xl px-2 py-2 lg:px-4 lg:py-0">
    <div class="flex items-center justify-between">
      <div class="flex w-full items-center justify-between gap-4 md:w-auto">
        <app-menu-mobile
          class="lg:hidden"
          [profile]="profile"
          (navigate)="navigateTo($event)"></app-menu-mobile>
        <a routerLink="/">
          <span class="sr-only">Logo</span>
          <img class="w-28 lg:w-32" src="assets/images/logo/dark.png" alt="Tribuu Logo" />
        </a>
        <div class="flex items-center justify-end gap-3 md:hidden">
          <ng-container *ngIf="!isAuthenticated()">
            <button
              data-testid="login-button"
              routerLink="/login"
              tribuuButton
              theme="light"
              size="sm"
              id="login-button-mobile"
              class="px-0 font-normal !text-black">
              Ingresar
            </button>
            <button
              data-testid="register-button"
              id="register-button-mobile"
              tribuuButton
              theme="dark"
              class="px-4 py-3"
              size="sm"
              (click)="openDialogRegister()">
              Registrarse
            </button>
          </ng-container>
          <ng-container *ngIf="isAuthenticated()">
            <app-notifications class="mr-2 rounded-full ring-[1.5px] ring-gray-300"></app-notifications
          ></ng-container>
        </div>
      </div>
      <app-top-menu-desktop
        class="animate-fade-in-bottom-delay-1s hidden grow lg:block"
        (navigate)="navigateTo($event)"
        [isAuth]="isAuthenticated()"
        [isTriber]="isTriber"
        [menuOptions]="menuOptions"></app-top-menu-desktop>

      <div class="hidden items-center justify-end gap-4 md:flex">
        <ng-container *ngIf="authLoaded()">
          <div class="fade-in-app flex justify-end gap-1" *ngIf="isAuthenticated()">
            <!-- icon notifications app -->
            <div class="flex-none">
              <app-notifications></app-notifications>
            </div>
            <div class="h-12 w-px bg-neutral-200"></div>
            <!-- icon messages app -->
            <div class="hidden sm:flex">
              <app-favorites-dropdown></app-favorites-dropdown>
              <!-- <app-messages></app-messages> -->
            </div>
            <!-- icon menu user app -->
            <app-menu-user
              data-testid="profile-menu"
              id="profile-user-menu-desktop"
              class="hidden flex-none sm:flex"
              [personalData]="personalData"></app-menu-user>
          </div>
          <div class="relative flex items-center gap-2 md:gap-3" *ngIf="!isAuthenticated()" id="divnoauth">
            <a
              id="login-button-desktop"
              routerLink="/login"
              class="hover:text-secondary text-sm font-medium tracking-normal text-gray-700 hover:underline md:text-sm lg:block lg:text-base">
              Inicia sesión
            </a>
            <button id="register-button-desktop" tribuuButton theme="primary" (click)="openDialogRegister()">
              Registrarse
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <!-- Search component navbar -->
  <div
    *ngIf="!isRouteToSearchExclude()"
    class="bg-secondary h-fit w-full overflow-hidden px-4 py-2 text-center transition-opacity duration-300 ease-in">
    <div class="container">
      <app-search-service class="mx-auto" color="secondary" />
    </div>
  </div>
</header>
