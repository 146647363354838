<app-wizard-onboarding>
  <p class="px-14 text-center text-3xl font-bold">¿Eres una persona o representas a una empresa?</p>

  <img src="assets/images/home/trazo.svg" alt="trazo" class="mx-auto mt-7 h-[35px]" />

  <p class="mt-7 text-center leading-tight">Selecciona una opción:</p>

  <div class="mt-7 flex w-full justify-center gap-5">
    <figure
      class="border-primary w-1/3 cursor-pointer rounded-xl border-2 p-5 max-md:px-2 max-sm:w-5/12"
      (click)="gotoCreateJobTriber()">
      <div class="flex h-[105px] w-full items-center justify-center">
        <img src="assets/images/home/wizard-talent-publish-tree.png" alt="opt icon" class="object-contain" />
      </div>
      <figcaption class="text-primary mt-5 w-full text-center text-lg font-bold leading-[18px]">
        Persona
      </figcaption>
    </figure>

    <figure
      class="border-primary w-1/3 cursor-pointer rounded-xl border-2 p-5 max-md:px-2 max-sm:w-5/12"
      (click)="companyClick()">
      <div class="flex h-[105px] w-full items-center justify-center">
        <img src="assets/images/home/wizard-talent-publish-four.png" alt="opt icon" class="object-contain" />
      </div>
      <figcaption class="text-primary mt-5 w-full text-center text-lg font-bold leading-[18px]">
        Empresa
      </figcaption>
    </figure>
  </div>

  <div class="mt-7 flex w-full justify-center" *ngIf="hasReturnButton">
    <button tribuuButton theme="dark" class="w-1/3" (click)="returnOnClick()">Regresar</button>
  </div>
</app-wizard-onboarding>
