import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WizardService } from '@core/services/internals/wizard.service';
import { INTERNAL_ROUTES } from '@data/constants/routes';
import { tuiPure } from '@taiga-ui/cdk';
import { SharedModule } from '../../../../shared/shared.module';
import { WizardOnboardingComponent } from '../../wizard-onboarding/wizard-onboarding.component';

@Component({
  selector: 'app-wizard-talent-publish',
  standalone: true,
  imports: [SharedModule, WizardOnboardingComponent],
  templateUrl: './wizard-talent-publish.component.html',
})
export class WizardTalentPublishComponent {
  private readonly router = inject(Router);
  private readonly routerActive = inject(ActivatedRoute);
  private readonly wizardService = inject(WizardService);

  private readonly isTalentPublishUrl =
    this.router.url.split('?')[0] === `/${INTERNAL_ROUTES.APP_WIZARD_TALENT_PUBLISH}`;

    @tuiPure
    get mainTile(): string {
    return this.isTalentPublishUrl
      ? '¿Qué modalidad laboral te gustaría publicar? '
      : '¿Qué tipo de vacante quieres publicar?';
  }

  @tuiPure
  get bdtText(): string {
    return this.isTalentPublishUrl ? 'Emplearme en la Bolsa de Trabajo' : 'Bolsa de trabajo';
  }

  @tuiPure
  get piText(): string {
    return this.isTalentPublishUrl
      ? 'Servicio Profesional Independiente'
      : 'Profesional Independiente';
  }

  @tuiPure
  get returnUrl(): string {
    return this.isTalentPublishUrl
      ? `/${INTERNAL_ROUTES.APP_WIZARD_WELCOME}`
      : `/${INTERNAL_ROUTES.APP_WIZARD_HIRE_TALENT}`;
  }

  @tuiPure
  get hasReturnButton() {
    return !this.routerActive.snapshot.queryParams?.hasReturnButton
      ? true
      : JSON.parse(this.routerActive.snapshot.queryParams.hasReturnButton);
  }

  onClickBDT(): void {
    let url = `/${INTERNAL_ROUTES.APP_WIZARD_TALENT_TYPE}`;

    if (this.isTalentPublishUrl) {
      this.wizardService.setOmit();
      url = INTERNAL_ROUTES.APP_ACCOUNT_FORM_ABOUT_ME_RESUME;
    }
    this.router.navigateByUrl(url);
  }

  onClickPI(): void {
    let url = `/${INTERNAL_ROUTES.APP_WIZARD_TALENT_TYPE}`;

    if (this.isTalentPublishUrl) {
      this.wizardService.setOmit();
      url = INTERNAL_ROUTES.APP_ACCOUNT_FORM_ABOUT_ME_PUBLICATION;
    }
    this.router.navigateByUrl(url);
  }
}
