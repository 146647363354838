import { Component, OnInit, inject } from '@angular/core';
import { FavoritesActions } from '@core/store/favorites/favorites.actions';
import { FavoritesState, FavoritesStateModel } from '@core/store/favorites/favorites.state';
import { Select, Store } from '@ngxs/store';
import { fadeIn } from '@shared/animations/fade-in-out.animation';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-favorites-dropdown',
  templateUrl: './favorites-dropdown.component.html',
  animations: [fadeIn],
})
export class FavoritesDropdownComponent implements OnInit {
  ngOnInit(): void {
    this.loadFavorites();
  }
  private store = inject(Store);
  @Select(FavoritesState) favoritesState$!: Observable<FavoritesStateModel>;

  removeFavorite(uid: string) {
    this.store.dispatch(new FavoritesActions.Remove(uid));
  }
  loadFavorites() {
    this.store.dispatch(new FavoritesActions.FetchAll());
  }
}
