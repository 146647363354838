import { Component, Input } from '@angular/core';
import { MenuOption } from '@layout/header/interfaces';

@Component({
  selector: 'app-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss'],
})
export class DropdownMenuComponent {
  @Input() icon = '';
  @Input() title = '';
  @Input() options: MenuOption[] = [];
}
