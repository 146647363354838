import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationState } from '@core/store/notification/notication.state';
import { NotificationActions } from '@core/store/notification/notification.actions';
import { TribuNotification } from '@tribuu-api';
import { Select, Store } from '@ngxs/store';
import { toLocalDate } from '@shared/helpers/datetime.helper';

import { Observable, map } from 'rxjs';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent {
  private store = inject(Store);
  private router = inject(Router);
  notifications = this.store.selectSignal(NotificationState.notifications);
  unreadCount = this.store.selectSignal(NotificationState.unreadCount);
  toLocalDate = toLocalDate;
  dropdownVisible = signal(false);

  removeNotification(uid: string) {
    this.store.dispatch(new NotificationActions.Remove(uid));
  }
  readNotifications(value: boolean) {
    if (value && this.unreadCount()) {
      this.store.dispatch(new NotificationActions.ReadAll());
    }
    this.dropdownVisible.set(value);
  }
  goto(route: string | null) {
    if (route) {
      this.router.navigateByUrl(route);
      this.dropdownVisible.set(false);
    }
  }
}
