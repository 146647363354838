import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Menu, itemMenu } from '@data/constants/config';

@Component({
  selector: 'app-top-menu-desktop',
  templateUrl: './top-menu-desktop.component.html',
  styleUrls: ['./top-menu-desktop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopMenuDesktopComponent {
  @Input() menuOptions!: Menu;
  @Input() isTriber!: boolean;
  @Input() isAuth!: boolean;
  @Output() navigate = new EventEmitter<itemMenu>();

  navigateTo(item: itemMenu) {
    this.navigate.emit(item);
  }
}
