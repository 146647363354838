<!-- Header -->
<app-header></app-header>
<!-- Page -->
<div class="relative min-h-screen">
  <router-outlet></router-outlet>
</div>
<!-- Footer -->
<app-footer></app-footer>

<!-- Messages conversations -->
<app-conversations></app-conversations>
