import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { tuiPure } from '@taiga-ui/cdk';
import { TuiDurationOptions, tuiFadeIn } from '@taiga-ui/core';

@Component({
  selector: 'app-banner-top-navbar',
  templateUrl: './banner-top-navbar.component.html',
  styleUrls: ['./banner-top-navbar.component.scss'],
  animations: [tuiFadeIn],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerTopNavbarComponent {
  @Output() openRegister = new EventEmitter<void>();
  @Input() hidden = false;
  isOpen = true;
  close(): void {
    this.isOpen = false;
  }
  @tuiPure
  getAnimation(): TuiDurationOptions {
    return { value: '', params: { duration: 500 } };
  }
}
