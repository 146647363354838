import { Component } from '@angular/core';
import { WizardOnboardingComponent } from '../../wizard-onboarding/wizard-onboarding.component';
import { RouterLink } from '@angular/router';
import { INTERNAL_ROUTES } from '@data/constants/routes';

@Component({
  selector: 'app-wizard-welcome',
  standalone: true,
  imports: [WizardOnboardingComponent, RouterLink],
  templateUrl: './wizard-welcome.component.html',
  styleUrls: ['./wizard-welcome.component.scss'],
})
export class WizardWelcomeComponent {
  readonly hireTalentURL = `/${INTERNAL_ROUTES.APP_WIZARD_HIRE_TALENT}`;
  readonly talentPublishURL = `/${INTERNAL_ROUTES.APP_WIZARD_TALENT_PUBLISH}`;
}
