import { Component, inject } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { WizardService } from '@core/services/internals/wizard.service';

@Component({
  selector: 'app-wizard-onboarding',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './wizard-onboarding.component.html',
})
export class WizardOnboardingComponent {
  private readonly wizardService = inject(WizardService);
  private readonly router = inject(Router);

  closeModal(): void {
    this.wizardService.setOmit();
    this.router.navigateByUrl('');
  }
}
