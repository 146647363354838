import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, delay, mergeMap, retryWhen } from 'rxjs/operators';

import { AlertService } from '@core/services/ui/alert.service';
import { AuthActions } from '@core/store/auth/auth.actions';
import { Store } from '@ngxs/store';
import { AuthLoginComponent } from '@shared/components/templates';
import { isMobile } from '@shared/utils';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';

export const maxRetries = 3;
export const delayMs = 3000;

@Injectable()
export class HttpErrorsInterceptor implements HttpInterceptor {
  private readonly dialogs = inject(TuiDialogService);
  private readonly alerts = inject(AlertService);
  constructor(private store: Store) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      // reintentar 3 veces con un delay de 3 seg
      retryWhen((error) =>
        error.pipe(
          mergeMap((error, index) => {
            if (index < maxRetries) {
              return of(error).pipe(delay(delayMs));
            }
            throw error;
          })
        )
      ),
      catchError((error: HttpErrorResponse) => {
        if (error) {
          if (error.error instanceof ErrorEvent) {
            // error en el lado del cliente
          } else {
            // error en el lado del servidor
            this.handleHttpError(error);
          }
        }
        throw error;
      })
    );
  }

  handleHttpError(err: HttpErrorResponse) {
    switch (err.status) {
      case 401:
        this.store.dispatch(new AuthActions.Logout());
        this.dialogs
          .open(new PolymorpheusComponent(AuthLoginComponent), {
            closeable: true,
            size: isMobile() ? 'fullscreen' : 'm',
            dismissible: true,
            required: true,
          })
          .subscribe();
        // todo - user unauthorized --> redirect to login
        break;
      case 403:
        // this.alerts.error('No tienes permiso para realizar esta operación.');

        break;

      case 429:
        this.alerts.error(
          'Haz sobrepasado el límite de solicitudes. Inténtalo de nuevo en unos minutos.'
        );
        break;
      case 409:
        const message = err.error.message;
        if (!message) break;

        this.alerts.warning(message, err.error.title);

        break;

      case 500:
        /// todo implementar logging, trace y alerta
        break;
    }
  }
}
