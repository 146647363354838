import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SidebarActions } from './sidebar.actions';

export interface SidebarStateModel {
  open: boolean;
}

/// TODO: Move to Config state and delete this
@State<SidebarStateModel>({
  name: 'sidebar',
  defaults: { open: false },
})
@Injectable()
export class SidebarState {
  @Selector()
  static open(state: SidebarStateModel): boolean {
    return state.open;
  }

  @Action(SidebarActions.Open)
  openSidebar({ patchState }: StateContext<SidebarStateModel>) {
    patchState({ open: true });
  }

  @Action(SidebarActions.Close)
  closeSidebar({ patchState }: StateContext<SidebarStateModel>) {
    patchState({ open: false });
  }
}
