import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthState } from '@core/store/auth/auth.state';
import { EMPTY_STRING } from '@data/constants/routes';
import { Store } from '@ngxs/store';
import { AuthLoginComponent } from '@shared/components/templates';
import { isMobile } from '@shared/utils';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { filter, switchMap, tap } from 'rxjs';

export const AuthGuard = (snap: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const store = inject(Store);
  const router = inject(Router);
  const dialogs = inject(TuiDialogService);

  const next$ = store.select(AuthState.isLoaded).pipe(
    filter(Boolean),
    switchMap(() => store.select(AuthState.isAuthenticated)),
    tap((isAuth) => {
      if (!isAuth) {
        dialogs
          .open(new PolymorpheusComponent(AuthLoginComponent), {
            closeable: true,
            size: isMobile() ? 'fullscreen' : 'm',
            dismissible: true,
            required: true,
          })
          .subscribe({
            next: () => router.navigateByUrl(state.url),
            error: () => router.navigateByUrl(EMPTY_STRING).then(() => location.reload()),
          });
      }
    })
  );
  return next$;
};
