/**
 * @todo - Documentar
 * @todo -  refactorizar y mejorar el código (funcion muy larga).
 * @todo - Mover los tipos a un archivo aparte.
 */
import { inject } from '@angular/core';
import { AngularFireDatabase, fromRef } from '@angular/fire/compat/database';
import { DatabaseReference } from '@angular/fire/compat/database/interfaces';
import { Message, RefundDataNotification, TribuNotification } from '@tribuu-api';
import { AuthState } from '@core/store/auth/auth.state';
import { ConversationsActions } from '@core/store/conversations/conversations.actions';
import {
  NotificationState,
  NotificationStateModel,
} from '@core/store/notification/notication.state';
import { NotificationActions } from '@core/store/notification/notification.actions';
import { PaymentActions } from '@core/store/payments/payments.actions';
import { ProfileActions } from '@core/store/profile/profile.actions';
import { ReservationActions } from '@core/store/reservations/reservations.actions';
import { SessionActions } from '@core/store/sessions/sessions.actions';
import { SessionsState } from '@core/store/sessions/sessions.state';
import { Store } from '@ngxs/store';
import { serverTimestamp } from 'firebase/database';
import { Subject, firstValueFrom } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
enum enumPushEvent {
  NEW_MESSAGE = 'new-message',
  NEW_NOTIFICATION = 'new-notification',
}
interface PushEvent<T extends AceptedPayload> {
  type: PushTypeDimanyc<T>;
  payload: T;
}
type AceptedPayload = Message | TribuNotification;
type PushTypeDimanyc<T extends AceptedPayload> = T extends Message
  ? enumPushEvent.NEW_MESSAGE
  : enumPushEvent.NEW_NOTIFICATION;

export function initializeApp(store: Store) {
  const realtimeFire = inject(AngularFireDatabase).database;
  const destroy$ = new Subject<boolean>();
  const userAuth$ = store.select(AuthState.userAuth).pipe(
    tap((user) => {
      if (!user) {
        destroy$.next(true);
      }
    }),
    filter(Boolean)
  ); // filter if user is null and destroy subscription if user is null, emit always user fire instance

  /**
   * Asynchronously sets up a listener for the 'value' event on the provided
   * DatabaseReference object and sets the value of 'connections' to true when
   * the database connection is established. Also, sets up a listener for
   * disconnection events and sets 'connections' to false when the connection is
   * lost.
   *
   * @param {DatabaseReference} ref - The DatabaseReference to listen to events on.
   * @return {Promise<void>} A Promise that resolves when the setup is complete.
   */
  // async function setPresence(ref: DatabaseReference) {
  //   const connectionsRef = ref.child('connections');
  //   const lastOnlineRef = ref.child('lastOnline');
  //   const connectedRef = realtimeFire.ref('.info/connected');
  //   connectedRef.on('value', snapshot => {
  //     const connected = snapshot.val();
  //     if (connected) {
  //       const newConnectionRef = connectionsRef.push();
  //       setConnectionOnDisconnect(newConnectionRef, lastOnlineRef);
  //       newConnectionRef.set(true);
  //     }
  //   });
  // }

  /**
   * Sets up a disconnection handler on the provided database references.
   *
   * @param {DatabaseReference} connectionRef - The reference to the connection node to set the disconnect handler for.
   * @param {DatabaseReference} lastOnlineRef - The reference to the last online node to set the disconnect handler for.
   */
  function setConnectionOnDisconnect(
    connectionRef: DatabaseReference,
    lastOnlineRef: DatabaseReference
  ) {
    connectionRef.onDisconnect().remove();
    lastOnlineRef.onDisconnect().set(serverTimestamp());
  }

  /**
   * Subscribes to notifications from the 'lastNotification' node in the database.
   * @param {DatabaseReference} ref - Reference to the 'lastNotification' node in the database.
   * @returns {void}
   */
  function subscribeToServerEvents(ref: DatabaseReference): void {
    fromRef<PushEvent<AceptedPayload>>(ref.child('lastNotification'), 'value', 'on')
      .pipe(
        takeUntil(destroy$),
        map((snapshot) => snapshot.payload.val()),
        filter(Boolean) // filters out null data
      )
      .subscribe({
        next: (event) => {
          switch (event.type) {
            case enumPushEvent.NEW_MESSAGE:
              handleMessage(event.payload as Message);
              break;
            case enumPushEvent.NEW_NOTIFICATION:
              handleNotification(event.payload as TribuNotification);
              break;
          }
        },
        complete: () => {
          // only for testing purposes
        },
      });
  }

  /**
   * Maneja una notificación de Tribu.
   * @param {TribuNotification} notification - La notificación que se va a manejar.
   * @param {StateContext<NotificationStateModel>} stateContext - El contexto del estado donde se almacenan las notificaciones.
   */
  function handleNotification(notification: TribuNotification) {
    const { loaded, notifications } = store.selectSnapshot<NotificationStateModel>(
      NotificationState.state
    );
    if (!loaded || notifications.some((n) => n.uid === notification.uid)) {
      return;
    }
    store.dispatch(new NotificationActions.Add(notification)); // add notification to state
    const payload = notification.payload as any;

    if (payload && payload.actionsOnReceive && payload.actionsOnReceive.length) {
      {
        payload.actionsOnReceive.forEach((action: string) => {
          if (action === 'sessions.update')
            store.dispatch(new SessionActions.FetchAll({ force: true }));

          if (action === 'reservations.update')
            store.dispatch(new ReservationActions.RetrieveAll({ force: true }));

          if (action === 'profile.update') store.dispatch(new ProfileActions.Fetch());

          if (typeof action === 'object') {
            const payload = action as RefundDataNotification;
            store.dispatch(new PaymentActions.RefundCredit(payload.data));
          }
        });
      }
    }
  }

  function handleMessage(message: Message) {
    const { reservationId, fromUser } = message;
    //Consultar sessiones del triber
    const sessions = store.selectSnapshot(SessionsState.sessions);
    if (!sessions.length) store.dispatch(new SessionActions.FetchAll({ force: true }));
    store.dispatch(new ConversationsActions.OpenConversation(reservationId, fromUser));
  }

  return (): Promise<boolean> => {
    /**
     * ----------------------------------------
     * Init resources for app in background
     * @todo move la escucha de notificaciones al state de notifications
     * ----------------------------------------
     */
    userAuth$.subscribe((user) => {
      const refUser = realtimeFire.ref(`users/${user.uid}`);
      // const refPresence = realtimeFire.ref(`presence/${user.uid}`);
      // setPresence(refPresence); // set presence in realtime database for user logged // future usage
      refUser
        .child('lastNotification')
        .set(null) // clean events before subscribe
        .then(() => subscribeToServerEvents(refUser)); // suscribe to server events
    });
    return firstValueFrom(store.select(AuthState.isLoaded).pipe(filter(Boolean))); /// wait for load auth state, init app
  };
}
