<div
  [@tuiFadeIn]="getAnimation()"
  *ngIf="isOpen"
  class="bg-tertiary relative isolate flex items-center gap-x-6 overflow-hidden px-6 py-2 transition-all duration-500 sm:px-3.5 sm:before:flex-1">
  <div
    class="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
    aria-hidden="true">
    <div
      class="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
      style="
        clip-path: polygon(
          74.8% 41.9%,
          97.2% 73.2%,
          100% 34.9%,
          92.5% 0.4%,
          87.5% 0%,
          75% 28.6%,
          58.5% 54.6%,
          50.1% 56.8%,
          46.9% 44%,
          48.3% 17.4%,
          24.7% 53.9%,
          0% 27.9%,
          11.9% 74.2%,
          24.9% 54.1%,
          68.6% 100%,
          74.8% 41.9%
        );
      "></div>
  </div>
  <div
    class="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
    aria-hidden="true">
    <div
      class="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
      style="
        clip-path: polygon(
          74.8% 41.9%,
          97.2% 73.2%,
          100% 34.9%,
          92.5% 0.4%,
          87.5% 0%,
          75% 28.6%,
          58.5% 54.6%,
          50.1% 56.8%,
          46.9% 44%,
          48.3% 17.4%,
          24.7% 53.9%,
          0% 27.9%,
          11.9% 74.2%,
          24.9% 54.1%,
          68.6% 100%,
          74.8% 41.9%
        );
      "></div>
  </div>
  <div class="flex w-full items-center justify-center gap-2 lg:gap-3">
    <p class="text-center text-xs text-gray-800 md:text-sm md:leading-6">
      Estamos buscando al <strong>MEJOR TALENTO</strong>.
      <strong><button class="underline" (click)="openRegister.emit()">¡Únete!</button></strong>
      <br />
      o déjanos tu correo para enterarte cuando puedas conectar con ellos.
    </p>
  </div>
  <div class="hidden flex-1 md:flex">
    <button type="button" class="-m-3 p-1.5 focus-visible:outline-offset-[-4px]" (click)="close()">
      <span class="sr-only">Dismiss</span>
      <svg class="h-6 w-6 text-gray-900" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path
          d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
      </svg>
    </button>
  </div>
</div>
