<nav aria-label="Site Nav" class="flex items-center justify-center gap-3 text-sm font-normal" id="desktopnav">
  <ng-container *ngFor="let opt of menuOptions; index as i">
    <div
      *ngIf="!opt.auth || (opt.auth && isAuth)"
      class="group flex items-center"
      id="{{ opt.id }}"
      tuiDropdownHover
      #dropdown="tuiDropdown"
      [tuiDropdownShowDelay]="0"
      [tuiDropdownHideDelay]="200"
      [tuiDropdownOffset]="4"
      [tuiDropdown]="opt.children ? content : null">
      <a
        (click)="navigateTo(opt)"
        class="opts-center group flex items-center justify-center gap-1.5 rounded-md p-2 pl-0 text-sm lg:text-base">
        <svg-icon
          *ngIf="opt.icon"
          class="h-6 w-6 transition-all duration-200 ease-in-out group-hover:text-indigo-600"
          [src]="opt.icon"></svg-icon>
        <span class="block max-w-[76px] leading-none text-gray-600 transition-all duration-200">
          {{ opt.title }}
        </span>
      </a>
      <svg-icon
        *ngIf="opt.children"
        class="h-5 w-5 transition-transform duration-150 group-hover:rotate-180"
        src="assets/images/icons/menu/chevron-down.svg"></svg-icon>
      <ng-template #content>
        <ul class="min-w-[10rem] divide-y divide-neutral-200">
          <ng-container *ngFor="let item of opt.children">
            <ng-container *ngIf="!item.auth || (item.auth && isAuth)">
              <ng-container *ngIf="!item.triber || (item.triber && isTriber)">
                <li
                  id="{{ item.id }}"
                  (click)="navigateTo(item)"
                  class="flex cursor-pointer gap-2 p-2 px-3 text-sm transition-all duration-200 ease-in-out hover:text-[#6CCA98]">
                  <img
                    *ngIf="item.image"
                    class="h-5 w-5 rounded-full"
                    [src]="item.image"
                    [alt]="item.title" />
                  <span>{{ item.title }}</span>
                </li>
              </ng-container>
            </ng-container>
          </ng-container>
        </ul>
      </ng-template>
    </div>
  </ng-container>
</nav>
