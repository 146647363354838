import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthActions } from '@core/store/auth/auth.actions';
import { SidebarActions } from '@core/store/sidebar/sidebar.actions';
import { TriberState } from '@core/store/triber/triber.state';

import { INTERNAL_ROUTES } from '@data/constants/routes';
import { Store } from '@ngxs/store';
import { isMobile } from '@shared/utils';

@Component({
  selector: 'app-menu-user-auth-opts',
  templateUrl: './menu-user-auth-opts.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuUserAuthOptsComponent {
  private readonly router = inject(Router);
  private store = inject(Store);
  triber$ = this.store.select(TriberState.triber);
  public ROUTES = INTERNAL_ROUTES;

  logout() {
    const sure = confirm('¿Estás seguro que deseas salir?');
    if (sure) this.store.dispatch(new AuthActions.Logout());
  }
  goto(route: string) {
    this.router.navigate([route]);
    if (isMobile()) {
      this.store.dispatch(new SidebarActions.Close());
    }
  }
}
