import { NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WizardService } from '@core/services/internals/wizard.service';
import { INTERNAL_ROUTES } from '@data/constants/routes';
import { TribuuButtonDirective } from '@shared/directives/tribuu-button.directive';
import { tuiPure } from '@taiga-ui/cdk';
import { WizardOnboardingComponent } from '../../wizard-onboarding/wizard-onboarding.component';

@Component({
  selector: 'app-wizard-role-type',
  standalone: true,
  imports: [WizardOnboardingComponent, TribuuButtonDirective, NgIf],
  templateUrl: './wizard-role-type.component.html',
})
export class WizardRoleTypeComponent {
  private readonly router = inject(Router);
  private readonly routerActive = inject(ActivatedRoute);
  private readonly wizardService = inject(WizardService);

  @tuiPure
  get hasReturnButton() {
    return !this.routerActive.snapshot.queryParams?.hasReturnButton
      ? true
      : JSON.parse(this.routerActive.snapshot.queryParams.hasReturnButton);
  }

  gotoCreateJobTriber(): void {
    this.wizardService.setOmit();
    this.router.navigateByUrl(INTERNAL_ROUTES.CREATE_JOB_TRIBER_PAGE);
  }

  companyClick(): void {
    this.wizardService.setOmit();
    this.router.navigate([INTERNAL_ROUTES.APP_COMPANIES_CREATE], {
      queryParams: { ref: 'vacante' },
    });
  }

  returnOnClick(): void {
    this.router.navigateByUrl(`/${INTERNAL_ROUTES.APP_WIZARD_HIRE_TALENT}`);
  }
}
