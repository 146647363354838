import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { ROUTES_PATHS } from '@data/constants/routes';
import { environment } from '@env';
import { map } from 'rxjs/operators';
import { StaticContentService } from '../services/static-content.service';

export const renderStaticHtmlGuard = (route: ActivatedRouteSnapshot) => {
  const staticContentService = inject(StaticContentService);
  const router = inject(Router);

  return staticContentService
    .getStaticContent(environment.staticContentPath + route.params[ROUTES_PATHS.APP.STATIC.PARAM])
    .pipe(
      map((r) => {
        if (!r) router.navigate(['/'], { replaceUrl: true });
        return r;
      })
    );
};
